import { Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {  UserLoginInfo } from "app/app-constants";
import { UtilityService } from "app/shared/utility.service";
import { EnvironmentSpecificService } from '../../environment-specific.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    apiUrl: string;
    public currentUser: Observable<UserLoginInfo>;
    constructor(
        private http: HttpClient,
        private utility:UtilityService, 
        private _envSpecificSvc: EnvironmentSpecificService) {
        this.apiUrl = `${_envSpecificSvc.envSpecific.apiURL}/auth`;
    }

    getAuthMethod(): Observable<any> {
        return this.http.get(`${this.apiUrl}/type`).pipe(catchError(this.utility.handleError('getAuthMethod')));
    }
    getOtp(username: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/otp/${username}`).pipe(
            catchError(this.utility.handleError("getopt")));
    }
    login(creds: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/login`, creds).pipe(
            // tap(res => {
            //     if (res.status) {
            //         this.saveUserInfo(res);
            //     }
            // },
                catchError(this.utility.handleError('login', [])));
    }

    logout(username: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/logout`, { username }).pipe(catchError(this.utility.handleError('logout', [])));
    }
    refresh(token: string, refreshToken: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/token/refresh`, { token, refreshToken });
    }
}
