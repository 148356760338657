import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'date',
    templateUrl: 'date.component.html'
})
export class DateComponent implements OnInit {
    model: NgbDateStruct;
    dateString: string;
    @Input('date') date: any;
    @Input('validator') validator: any;
    @Output() dateChange: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();
    lastDate: any;
    expiryDate: any;
    constructor(
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.expiryDate = this.date;
        this.model = this.setDefaultDate(this.date);
        this.lastDate = this.model;
        //this.onSelectDate(this.model);
    }


    onSelectDate(date: any) {
        if (date != null) {
            if (this.validator) {
                let expDate = moment(new Date(date.year, date.month, date.day)).add(-1, 'month');
                if (expDate.isAfter(this.expiryDate)) {
                    this.toastr.error(this.validator.message);
                    this.model = this.setDefaultDate(this.getDate(this.lastDate));
                    return;
                }
                var d = new Date()
                let currDate = moment(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
                if (currDate.isAfter(expDate)) {
                    this.toastr.error(this.validator.messageCurrentDate);
                    this.model = this.setDefaultDate(this.getDate(this.lastDate));
                    return;
                }
            }
            //this.date = date;
            //this.model = date;   //needed for first time around due to ngModel not binding during ngOnInit call. Seems like a bug in ng2.
            //this.dateString = this.ngbDateParserFormatter.format(date);
            this.lastDate = date;
            this.dateChange.emit(date);
        }
    }

    getDate(date: NgbDate) { return moment(new Date(date.year, date.month, date.day)).add(-1, 'month'); }

    setDefaultDate(date): NgbDateStruct {
        let startYear = date.year();
        let startMonth = date.month() + 1;
        let startDay = date.date();

        return this.ngbDateParserFormatter.parse(startYear + "-" + startMonth.toString() + "-" + startDay);
    }
}