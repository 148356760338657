import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '../utility.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentSpecificService } from '../../environment-specific.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    private utility: UtilityService, 
    private _envSpecificSvc: EnvironmentSpecificService) {
      this.apiUrl = `${_envSpecificSvc.envSpecific.apiURL}/dashboard`;
  }

  get(): Observable<any> {
    return this.http.get(`${this.apiUrl}`).pipe(catchError(this.utility.handleError("getDashboard", [])));
  }
  getStorage(): Observable<any> {
    return this.http.get(`${this.apiUrl}/storageUsage`).pipe(catchError(this.utility.handleError("getDashboard", [])));
  }
}
