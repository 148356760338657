import { Component, Input, AfterViewChecked, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { Chart, ChartConfiguration } from 'chart.js';

@Component({
  selector: 'traffic-chart',
  templateUrl: './trafficChart.html',
  styleUrls: ['./trafficChart.scss']
})

// TODO: move chart.js to it's own component
export class TrafficChart implements AfterViewInit {


  chart: any;
  @Input() chartOptions: ChartConfiguration;
  @Input() chartLabel: string;
  @Input() chartId:string;
  @Input() total: any;

  @ViewChild("chartName",{static:false}) chartName:ElementRef;

  constructor() {
  }

  
  ngAfterViewInit() {
   // let el = this.chartName.nativeElement;
    this.chart = new Chart(this.chartName.nativeElement.getContext("2d"), this.chartOptions);
    // let v: Array<any> = this.chartOptions.data.datasets[0].data;
    // this.total = v.reduce((a, b) => a + b);
  }
}
