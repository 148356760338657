import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, Renderer, Inject, NgZone } from '@angular/core';
import { UploaderOptions } from 'ngx-uploader';

@Component({
  selector: 'ba-file-uploader',
  styleUrls: ['./baFileUploader.scss'],
  templateUrl: './baFileUploader.html',
})
export class BaFileUploader {
    @Input() options: UploaderOptions = {concurrency:10, allowedContentTypes: [''] };
    @Input() defaultValue: string = '';
    @Input() uploadButtonCaption: string = 'form.button.upload';

    @Output() onFileUpload = new EventEmitter<any>();
    @Output() onFileUploadCompleted = new EventEmitter<any>();

    @Output() onStartUpload = new EventEmitter<any>();
    @Output() onBeforeFileUpload = new EventEmitter<any>();

  // @ViewChild('fileUpload') public _fileUpload: ElementRef;
  // @ViewChild('inputText') public _inputText: ElementRef;
  @ViewChild('fileUpload', {static: true}) _fileUpload: ElementRef;
  @ViewChild('inputText', {static: true}) _inputText: ElementRef;


  public uploadFileInProgress: boolean;
  response: any;
  previewData: any;
  hasBaseDropZoneOver: boolean;
  events: EventEmitter<string>;

  constructor(private renderer: Renderer, @Inject(NgZone) private zone: NgZone) {
      this.events = new EventEmitter<string>();
  }

  bringFileSelector(): boolean {
    this.renderer.invokeElementMethod(this._fileUpload.nativeElement, 'click');
    return true;
}

beforeFileUpload(uploadingFile: any): void {

    let files = this._fileUpload.nativeElement.files;

    if (files.length) {


        this.onBeforeFileUpload.emit();

        const file = files[0];

        // if (!this._canFleUploadOnServer()) {

        //     uploadingFile.setAbort();
        // } else {

        //     this.uploadFileInProgress = true;
        // }
        this.uploadFileInProgress = true;

    }
}

fileOverBase(e: boolean) {
    this.hasBaseDropZoneOver = e;
}

handlePreviewData(data: any) {
    this.previewData = data;
}

_onChangeFileSelect(file) {

    this._inputText.nativeElement.value = file.name;
}

_onFileUpload(data): void {
    setTimeout(() => {

        this.zone.run(() => {

            this.response = data;

            if (!this.uploadFileInProgress) {
                return;
            }
            if (data && data.response) {

                this.response = JSON.parse(data.response);

                if (data['done'] || data['abort'] || data['error']) {

                    this._onFileUploadCompleted(data);

                } else {

                    this.onFileUpload.emit(data);
                }
            }
        });
    });
}

startUpload() {

    this.onStartUpload.emit();

    // if (!this.fileUploaderOptions.autoUpload) {
    //     this.fileUploaderEvents.emit('startUpload');
    // }
}

_onFileUploadCompleted(data): void {
    this.uploadFileInProgress = false;
    this.onFileUploadCompleted.emit(data);
}

// _canFleUploadOnServer(): boolean {
//    // return !!this.fileUploaderOptions['url'];
// }

_fileChangeEvent(fileInput: any) {

    if (fileInput.target.files && fileInput.target.files[0]) {
        let file = fileInput.target.files[0];
        let fileNameWithExtension = file.name.split('.');

        let extension: string = fileNameWithExtension[fileNameWithExtension.length - 1];
        extension = extension.toLowerCase();

        if (this.options.allowedContentTypes.indexOf(extension) == -1) {
            return;
        }

        this._inputText.nativeElement.value = fileInput.target.files[0].name;
    }
}

  // _canFleUploadOnServer(): boolean {
  //   return !!this.fileUploaderOptions['url'];
  // }
}
