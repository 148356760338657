import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "../modal";

@Component({
    selector: "confirm-modal",
    styleUrls: ["./confirm-modal.component.scss"],
    templateUrl: "./confirm-modal.component.html"
})
export class ConfirmModal implements OnInit {

    modal:Modal;
    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() { 
    }

    closeModal(result: any) {
        
        this.activeModal.close(result);

    }
}
