import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, Input, ElementRef, HostListener, Renderer, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { NgxPicaService, NgxPicaErrorInterface, NgxPicaResizeOptionsInterface } from '@digitalascetic/ngx-pica';


const FILE_SIZE: number = 2097152;
const HEIGHT: number = 244;
const WIDTH: number = 244;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {

  onChange: Function;
  private file: File | null = null;
  defaultPicture: string;
  @Input() pageName: string;
  uploadInProgress: boolean;
  @Input() picture: string = '';
  @Input() preview: string = '';
  @Output() photoUpload = new EventEmitter<any>();
  fileFormats: any;
  fileTypeMessage: string;
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    if (file) {
      let type = file.type.split('/')[1];
      if (this.fileFormats.includes(type) && file.size <= FILE_SIZE) {
        let _files = [file];
        let _options: NgxPicaResizeOptionsInterface = {
          aspectRatio: {
            keepAspectRatio: true
          },
          alpha: true
        };
        this.picaService.resizeImages(_files, HEIGHT, WIDTH, _options)
          .subscribe((imageResized: File) => {
            this.onChange(imageResized);
            this.file = imageResized;
            let reader: FileReader = new FileReader();

            reader.addEventListener('load', (event: any) => {
              this.removePicture();
              this.picture = (<any>event.target).result;
      
              //this.preview = this.picture;
              this.photoUpload.emit(this.picture);
            }, false);
            reader.readAsDataURL(this.file);

          }, (err: NgxPicaErrorInterface) => {
            throw err.err;
          });

        // this.onChange(file);
        // this.file = file;
        // //preview
        // const reader = new FileReader();
        // reader.addEventListener('load', (event: Event) => {
        //   this.removePicture();
        //   this.picture = (<any>event.target).result;
        //   console.log(this.picture);
        //   this.preview = this.picture;
        //   this.photoUpload.emit(this.picture);
        // }, false);
        // reader.readAsDataURL(file);
      }
    }
    else {
      this.removePicture();
    }
    // reader.onload = () => {
    //   let fileData = {
    //     name:file.name,
    //     type:file.type,
    //     value:reader.result.split(';')[1]
    //   }
    //   this.photoUpload.emit(fileData);
    // }
  }
  ngOnInit() {
    if (this.pageName == "users") {
      this.fileFormats = ["jpeg", "png", "jpg"];
      this.fileTypeMessage = "Only JPEG, JPG and PNG files (Max. 2MB)"
      this.defaultPicture = "assets/img/theme/no-photo.png";
    }
    if (this.pageName == "customers") {
      this.fileFormats = ["png"];
      this.fileTypeMessage = "Only PNG files (Max. 2MB)"
      this.defaultPicture = "assets/img/app/enterprise.png";
    }
    this.picture = this.preview;
    // console.log(this.picture);
  }

  @ViewChild('fileUpload', { static: true }) _fileUpload: ElementRef;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private renderer: Renderer,
    private picaService: NgxPicaService
  ) {
  }
  bringFileSelector() {
    this.renderer.invokeElementMethod(this._fileUpload.nativeElement, 'click');
    return false;
  }

  compress(file: any) {

  }
  removePicture() {
    this.picture = "";
    this.preview = "";
    this.photoUpload.emit("");
  }
  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }

}