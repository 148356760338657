import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ba-card',
  templateUrl: './baCard.html',
})
export class BaCard {
  @Input() cardTitle: String;
  @Input() baCardClass: String;
  @Input() cardType: String;

  @Input() showExportButton: boolean = false;
  @Output() exportButtonClick = new EventEmitter<any>();

  @Input() showAddButton: boolean = false;
  @Output() addButtonClick = new EventEmitter<any>();
  @Input() disableAddButton: boolean = false;

  @Input() showToggleButton: boolean = false;

  @Input() showEmail: boolean = false;
  @Input() disableEmail: boolean = false;
  @Input() showPayment: boolean = false;
  @Output() emailClick = new EventEmitter<any>();
  @Output() viewPaymentClick = new EventEmitter<any>();

  @Input() toggleButtonCaption: string = "";
  @Input() toggleStatus: boolean = false;
  @Output() toggleButtonClick = new EventEmitter<any>();

  onToggleButtonClick(event) {
    this.toggleStatus = !this.toggleStatus;
    this.toggleButtonClick.emit(this.toggleStatus);
  }
  onExportButtonClick() {
    this.exportButtonClick.emit();
  }
  onAddButtonClick() {
    this.addButtonClick.emit();
  }
  onEmailClick() {
    this.emailClick.emit();
  }
  onPaymentClick() {
    this.viewPaymentClick.emit();
  }
}
