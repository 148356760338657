import { Component } from '@angular/core';
import { Routes } from '@angular/router';
import * as _ from 'lodash';

import { BaMenuService } from '../theme';
import { PAGES_MENU } from './pages.menu';
import { AuthService } from './login/auth.service';
import { UserProfileService } from './login/user-profile/user-profile.service';
import { UserSessionService } from './login/user-session.service';

@Component({
  selector: 'pages',
  template: `
    <ba-sidebar></ba-sidebar>
    <ba-page-top [userImage]="userImage"></ba-page-top>
    <div class="al-main">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
      <div class="al-footer-right" translate>{{'general.created_by_wolfcom'}}  <img src="assets/img/app/wolfcom-icon16x16.png"></div>
      <div class="al-footer-main clearfix">
        <div class="al-copy">&copy; <a href="http://www.wolfcomusa.com/" translate>{{'Wolfcom Enterprises'}}</a> 2018</div>
        <ul class="al-share clearfix">
        <a href="https://www.facebook.com/WolfcomUSA/" target="_blank"><li><i class="socicon socicon-facebook"></i></li></a>
        <a href="https://twitter.com/wolfcom_usa/" target="_blank"><li><i class="socicon socicon-twitter"></i></li></a>
          
        </ul>
      </div>
    </footer>
    <ba-back-top position="200"></ba-back-top>
    `
})
export class Pages {
  userImage:string;
  constructor(
    private _menuService: BaMenuService,
    private _authService: AuthService,
    private userSession: UserSessionService,
    private _userProfile:UserProfileService) {
  }

  ngOnInit() {
    let _user = this.userSession.currentUserValue;
    let filteredMenu = _.cloneDeep(PAGES_MENU);
    if (_user.role.toLowerCase() == "user") {
      filteredMenu[0].children = this.menuFilter(filteredMenu[0].children);
    }
    this._menuService.updateMenuByRoutes(<Routes>filteredMenu);
    this._userProfile.getPicture().subscribe(res=>{
    this.userImage =  res.pic ?  `data:image/jpeg;base64,${res.pic}` : "assets/img/theme/no-photo.png";
    });
  }

  menuFilter(menu: any[]) {
    for (let index = 0; index < menu.length; index++) {
      const element = menu[index];
      if (element.role && element.role.toLowerCase() == "admin")
        menu.splice(index--, 1);
      else
        if (element.children)
          menu = this.menuFilter(element.children);
    }
    return menu;
  }
}
