import { Component, OnChanges, SimpleChanges, Input, ViewChild, ElementRef, forwardRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';

export class PinCode {

    index: number;
    value: any;
    isValid: boolean;
    touched: boolean = false;

    constructor(index: number) {

        this.index = index;
        this.value = '';
    }
}

@Component({
    selector: 'pincode',
    templateUrl: './pin-code.html',
    styleUrls: ['./pin-code.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PinCodeComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PinCodeComponent),
            multi: true
        }
    ]
})
export class PinCodeComponent implements OnChanges, ControlValueAccessor, Validator, AfterViewInit {

    // Pin code length default is 4 digit
    @Input() length: number;
    @Input() focus: boolean = false;
    @Input('value') _value = '';
    @Input('disabled') _disabled: boolean = false;

    pincodeNumbers: Array<PinCode>;

    private onTouched: () => void;
    private onChange: (_: any) => void;

    @ViewChild('pincode',{static:true}) pincodeElement: ElementRef;

    ngOnChanges(changes: SimpleChanges): void {

        if (changes["length"]) {

            this.init();
        }

        if (changes["focus"]) {

            let element = <HTMLCanvasElement>this.pincodeElement.nativeElement.querySelector('#pincode0');

            if (element) {

                setTimeout(() => element.focus(), 0);
            }
        }
    }

    ngAfterViewInit(): void {

        if (this.focus) {

            let element = <HTMLCanvasElement>this.pincodeElement.nativeElement.querySelector('#pincode0');

            if (element) {

                setTimeout(() => element.focus(), 0);
            }
        }
    }

    constructor() {

        this.init();
    }

    get value() {

        return this._value;
    }

    set value(val) {

        this._value = val;

        this.onChange(val);

        this.onTouched();
    }

    get disabled(): boolean {

        return this._disabled;
    }

    set disabled(val) {

        this._disabled = val;
    }

    // Validator
    validate(control: FormControl): ValidationErrors {

        if (this.value && this.value.length != this.length) {

            return {
                invalid: true
            };
        }
        return null;
    }

    registerOnChange(fn) {

        this.onChange = fn;
    }

    registerOnTouched(fn) {

        this.onTouched = fn;
    }

    writeValue(value) {

        if (value) {

            this.value = value;
            this.initValue(value);
            return;
        }

        this.clear();
    }

    private init() {

        this.pincodeNumbers = new Array<PinCode>();

        for (let index = 0; index < this.length; index++) {

            this.pincodeNumbers.push(new PinCode(index));
        }
    }

    private onKeyDown(event: KeyboardEvent, id: number) {

        if (event.target
            && (event.keyCode == 8 || event.keyCode == 46
                || (event.keyCode >= 48 && event.keyCode <= 57)
                || (event.keyCode >= 96 && event.keyCode <= 105))) {

            let input = <HTMLInputElement>event.target;

            let value = input.value;

            if (!isNaN(Number(value))) {

                return true;
            }
        }
        event.preventDefault();
        return false;
    }

    private checkPin(event: KeyboardEvent, id: number) {

        if (event.target
            && (event.keyCode == 8 || event.keyCode == 46
                || (event.keyCode >= 48 && event.keyCode <= 57)
                || (event.keyCode >= 96 && event.keyCode <= 105))) {

            let input = <HTMLInputElement>event.target;

            let value = input.value;

            if (value.trim() == "" || isNaN(Number(value))) {

                input.value = '';

                this.pincodeNumbers[id].value = '';

                this.pincodeNumbers[id].isValid = false;

                this.value = this.getPinCode();

                event.preventDefault();

                return;
            }

            input.value = value.replace(/\D/g, '');

            this.pincodeNumbers[id].value = input.value;

            this.pincodeNumbers[id].isValid = true;

            this.value = this.getPinCode();

            let element = <HTMLCanvasElement>this.pincodeElement.nativeElement.querySelector('#' + input.attributes["next"].value);

            if (element) {

                setTimeout(() => element.focus(), 0);

            } else {

                input.blur();
            }
        }

        event.preventDefault();
        return false;
    }

    private onFocus(event, id: number) {

        event.target.select();
        this.pincodeNumbers[id].touched = true;
    }

    private onBlur(event, id: number) {

        if (event.target) {

            let input = <HTMLInputElement>event.target;
            let value = input.value;

            if (value == "") {

                value = value.slice(0, 0);

                event.preventDefault();

                this.pincodeNumbers[id].isValid = false;
                return;
            }
        }
    }

    private getPinCode(): string {

        return this.pincodeNumbers.map(e => e.value).join("");
    }

    private initValue(value: string) {

        if (!value) {
            return;
        }

        let valueString = value.toString();

        this.length = valueString.length;

        let pincodeValueArray = valueString.split('');

        this.pincodeNumbers = new Array();

        for (let index = 0; index < pincodeValueArray.length; index++) {

            let pincode = new PinCode(index);

            if (!isNaN(Number(pincodeValueArray[index]))) {

                pincode.value = pincodeValueArray[index];
                pincode.isValid = true;

            } else {

                pincode.value = '';
                pincode.isValid = false;
            }

            this.pincodeNumbers.push(pincode);
        }
    }

    private clear() {

        for (let index = 0; index < this.pincodeNumbers.length; index++) {

            this.pincodeNumbers[index].value = "";
            this.pincodeNumbers[index].isValid = true;
            this.pincodeNumbers[index].touched = false;
        }

        let element = <HTMLCanvasElement>this.pincodeElement.nativeElement.querySelector('#pincode0');

        if (element && this.focus) {

            setTimeout(() => element.focus(), 0);
        }
    }
}
