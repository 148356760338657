import { Routes, RouterModule } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from 'app/shared/gaurds/auth.guard';
import { InvalidConfigComponent } from './settings/invalid-config/invalid-config.component';
// noinspection TypeScriptValidateTypes
import { EnvironmentSpecificResolver } from 'app/environment-specific-resolver.service';
// export function loadChildren(path) { return System.import(path); };

export const routes: Routes = [
  {
    path: 'system-config',
    resolve: { envSpecific: EnvironmentSpecificResolver },
    loadChildren: () => import('./settings/invalid-config/invalid-config.module').then(m => m.InvalidConfigModule)
  },
  {
    path: 'access-code',
    resolve: { envSpecific: EnvironmentSpecificResolver },
    loadChildren: () => import('./access-code/access-code.module').then(m => m.AccessCodeModule)
  },
  {
    path: 'login',
    //canActivate:[LoginGuard],
    resolve: { envSpecific: EnvironmentSpecificResolver },
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    resolve: { envSpecific: EnvironmentSpecificResolver },
    loadChildren: 'app/pages/register/register.module#RegisterModule'
  },
  {
    path: 'payment-notify',
    resolve: { envSpecific: EnvironmentSpecificResolver },
    loadChildren: () => import("./payment-notify/payment-notify.module").then(x => x.PaymentNotifyModule)
  },
  {
    path: 'pages',
    component: Pages,
    // canActivate:[AuthGuard],
    resolve: { envSpecific: EnvironmentSpecificResolver },
    children: [
      //  { path: '', redirectTo: 'access-code', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
      { path: 'userprofile', loadChildren: () => import('./login/user-profile/user-profile.module').then(m => m.UserProfileModule), canActivate: [AuthGuard] },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard], data: { role: "Admin" } },
      { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule), canActivate: [AuthGuard] },
      { path: 'packages', loadChildren: () => import('./packages/packages.module').then(m => m.PackagesModule), canActivate: [AuthGuard], data: { role: "Admin" } },
      { path: 'accounts', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard] },
      { path: 'payments', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule), canActivate: [AuthGuard] },
      { path: 'addons', loadChildren: () => import('./addons/addons.module').then(m => m.AddonsModule), canActivate: [AuthGuard], data: { role: "Admin" } },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard], data: { role: "Admin" } },
      { path: 'security', loadChildren: () => import('./security/security.module').then(m => m.SecurityModule), canActivate: [AuthGuard], data: { role: "Admin" } },
      { path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule), canActivate: [AuthGuard] }
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
