import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { currentUserKey, UserLoginInfo, accessCodeKey } from "app/app-constants";
import { EnvironmentSpecificService } from '../../environment-specific.service';
@Injectable({
    providedIn: 'root'
})
export class UserSessionService {

    apiUrl: string;
    private currentUserSubject: BehaviorSubject<UserLoginInfo>;
    public currentUser: Observable<UserLoginInfo>;
    constructor( private _envSpecificSvc: EnvironmentSpecificService) {
        this.currentUserSubject = new BehaviorSubject<UserLoginInfo>(JSON.parse(localStorage.getItem(currentUserKey)));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): UserLoginInfo {
        return this.currentUserSubject.value;
    }

     saveUserInfo(res: any) {
        let userInfo: UserLoginInfo = new UserLoginInfo();
        userInfo = res;
        localStorage.setItem(currentUserKey, JSON.stringify(userInfo));
        this.currentUserSubject.next(userInfo);
    }
    clearLoginSession() {
        if(!this._envSpecificSvc.envSpecific.production) return;//not running in dev env
        localStorage.removeItem(currentUserKey);
        localStorage.removeItem(accessCodeKey);
        this.currentUserSubject.next(null);
    }



}
