import { Component, OnInit, Output, AfterViewInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDatepicker, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from '../accounts.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DateComponent } from 'app/shared/controls/date-picker/date.component';


declare let paypal: any;

@Component({
  selector: 'app-paypal-modal',
  templateUrl: './paypal-modal.html',
  styleUrls: ['./paypal-modal.scss']
})
export class PaypalModal implements OnInit, AfterViewInit {

  offline: boolean = false;
  package: any;
  didPaypalScriptLoad: any;
  data: any;
  totalPrice: any;
  desText: any;
  clientId = "AQL_NgEzgmCsb6RQt5XbqK0Bq-Qn7eMe5Lqgn1tyIJOQXMuqKbZkK6j-HG-WSAdHhxCap2VfoW5og_mc";
  inProgress: boolean;
  expiryDate: NgbDateStruct;
  addons: Array<any>;
  addon: any;
  quantity: number;
  title: string;
  submitted: boolean;
  addOnduration: number;
  accExpiryDate: any;

  validator: { expiryDate: NgbDateStruct; message: string; messageCurrentDate: string; };
  addOnExpiryDate: moment.Moment;

  constructor(
    private _activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private accountService: AccountsService) { }
  ngAfterViewInit(): void {
    this.initPaypal();

  }
  ngOnInit() {
    this.package = this.data.package;
    this.clientId = this.data.clientId;
    if (this.package.total.toString().indexOf('.') == -1) {
      this.totalPrice = this.package.total;
      this.desText = "00";
    }
    else {
      let arr = this.package.total.toString().split('.');
      this.totalPrice = arr[0];
      this.desText = arr[1];
    }

    this.addons = this.package.addons
    this.title = this.addons ? "Buy Add-on" : "Buy Package";

    if (this.addons) {
      this.accExpiryDate = this.data.expiryDate;
      this.addOnExpiryDate = this.data.expiryDate;
      this.addOnduration = this.package.duration;
      this.validator = {
        expiryDate: this.data.expiryDate,
        message: "Add-on Expiry Date cannot be greater than Account Expiry Date!",
        messageCurrentDate: "Expiry date must not be a past date!"
      };
    }

  }

  onAddonChange(value) {
    if (!value) {
      this.totalPrice = 0;
      this.quantity = 0;
    }
    else {
      this.addon = this.addons.find(x => x.value == value);
      this.package.priceMontly = this.addon.price;
      if (this.quantity > 0)
        this.updateTotalPrice(this.addon.price, this.addOnduration, this.quantity);
    }
  }
  onQuantityChange(qty) {
    if (!qty) {
      this.totalPrice = 0;
      this.quantity = 0;
    }
    else {
      this.quantity = parseInt(qty);
      this.updateTotalPrice(this.addon.price, this.addOnduration, this.quantity);

    }
  }
  makeOffline() {
    this.expiryDate = this.data.expiryDate;
    this.offline = true;
  }
  payOffline(payId: string) {
    var d = new Date()
    let currDate = moment(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
    let expDate = moment(new Date(this.expiryDate.year, this.expiryDate.month, this.expiryDate.day)).add(-1, 'month');
    if (currDate.isAfter(expDate)) {
      this.toastr.error("Expiry date must not be a past date!");
      return;
    }
    else
      this.accountService.paymentCompleteOffline(payId, this.package.accountId, expDate).subscribe(res => {
        if (res == 200) {
          this.toastr.success("Payment added successfully!");
          this._activeModal.close(200);
        } else {
          this.toastr.error("Unable to add payment! An unexpected error occurred.");
          this._activeModal.close(500);
        }
      });
  }
  payOfflineAddon(payId: string) {
    let addon = {
      id: this.addon.value,
      quantity: this.quantity,
      duration: this.addOnduration
    };
    this.accountService.paymentCompleteOfflineAddon(payId, addon, this.addOnExpiryDate, this.package.accountId).subscribe(res => {
      if (res == 200) {
        this.toastr.success("Payment added successfully!");
        this._activeModal.close(200);
      } else {
        this.toastr.error("Unable to add payment! An unexpected error occurred.");
        this._activeModal.close(500);
      }
    });
  }
  //
  onClose(result: any) {
    this._activeModal.close(result);
  }

  onDateChange(date: any) {
    this.expiryDate = date;
  }

  onAddonDateChange(date: any) {
    this.addOnExpiryDate = moment(new Date(date.year, date.month, date.day)).add(-1, 'month');
    let currDate = moment();
    this.addOnduration = this.addOnExpiryDate.diff(currDate, 'month') + 1;
    if (this.addon)
      this.updateTotalPrice(this.addon.price, this.addOnduration, this.quantity);
  }

  private updateTotalPrice(price: any, duration: number, quantity: number) {
    if (price)
      this.totalPrice = (parseInt(price) * duration) * quantity;
  }

  public initPaypal() {
    let that = this;
    paypal.Buttons({
      createOrder: (data, actions) => {
        that.submitted = true;
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: that.totalPrice
            }
          }]
        });
      },
      onApprove: (data, actions) => {
        that.inProgress = true;
        actions.order.capture().then(function (details) {
          let addon: any;
          let response: Observable<any>;
          if (that.addon) {
            addon = {
              accountId: that.package.accountId,
              orderId: data.orderID,
              expiryDate: that.addOnExpiryDate,
              addon: {
                id: that.addon.value,
                quantity: that.quantity,
                duration: that.package.duration
              }
            };
            response = that.accountService.notifyPaymentCompleteAddon(addon);
          }
          else
            response = that.accountService.notifyPaymentComplete(data.orderID, that.package.accountId);

          response.subscribe(res => {
            if (res == 200) {
              that.toastr.success("Payment processed successfully!");
              that._activeModal.close(200);
            } else
              if (res = 503) {
                that.toastr.error("Unable to send email notification! An unexpected error has occurred.");
                that._activeModal.close(500);
              }
              else {
                that.toastr.error("Unable to process payment! An unexpected error occurred.");
                that._activeModal.close(500);
              }
            that.inProgress = false;
          });
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        that._activeModal.close();
      },
      onError: err => {
        console.log('OnError', err);
        let addon: any;
        if (that.addon) {
          addon = {
            accountId: that.package.accountId,
            orderId: "test",
            expiryDate: that.addOnExpiryDate,
            addon: {
              id: that.addon.value,
              quantity: that.quantity,
              duration: that.package.duration
            }
          }
          that.accountService.notifyPaymentCompleteAddon(addon).subscribe(res => {
            if (res == 200)
              that.toastr.error("Unable to process payment! An unexpected error occurred.");
            else
              that.toastr.error("Unable to process payment! An unexpected error occurred.");
            that._activeModal.close();
          });
        }
        else {
          that.accountService.notifyPaymentComplete("asdas", that.package.accountId).subscribe(res => {
            if (res == 200)
              that.toastr.error("Unable to process payment! An unexpected error occurred.");
            else
              that.toastr.error("Unable to process payment! An unexpected error occurred.");
            that._activeModal.close();
          });
        }

      }
    }).render('#paypal-button-container');
  }

  public loadPaypalScript()//: Promise<any>
  {
    this.didPaypalScriptLoad = true;
    // return new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script');
    // scriptElement.src = 'https://www.paypalobjects.com/api/checkout.js';
    scriptElement.src = `https://www.paypal.com/sdk/js?client-id=${this.clientId}`;

    //scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
    //});
  }



}
