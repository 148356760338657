import { Injectable } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModal } from "./confirm/confirm-modal.component";
import { Modal } from "./modal";
import { PINCodeModal } from "./pin-code/pincode-modal.component";
import { AuthMethod } from "app/pages/login/AuthMethod";
import { InfoModal } from "./default/info-modal.component";
import { ChangePasswordPinModal } from "app/pages/login/user-profile/change-password-pin/change-password-pin.component";
import { NgModel } from "@angular/forms";
import { AccessCode } from "app/pages/access-code/access-code.component";
import { AccessCodeModal } from "./access-code/accessCode-modal.component";

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(
        private modal: NgbModal,
        //private activeModal:NgbActiveModal
    ) { }
    Confirm(modalData: Modal): Promise<any> {

        if (!modalData.header) {
            modalData.header = "Dialog prompts";
        }

        let activeModal = this.modal.open(ConfirmModal, {
            size: "sm",
            backdrop: "static"
        });

        activeModal.componentInstance.modal = modalData
        return activeModal.result;
    }
    info(header: string, content: string): Promise<any> {
        let activeModal = this.modal.open(InfoModal, {
            size: "sm",
            backdrop: "static"
        });

        activeModal.componentInstance.header = header
        activeModal.componentInstance.content = content
        return activeModal.result;
    }

    PinCode(authMode: AuthMethod, loginInfo: any): Promise<any> {
        let activeModal = this.modal.open(PINCodeModal, {
            size: "sm",
            backdrop: "static",
            windowClass: "modal-custom-pincode-input"
        });
        activeModal.componentInstance.authMode = authMode;
        activeModal.componentInstance.loginInfo = loginInfo;
        return activeModal.result;
    }
    accessCode(): Promise<any> {
        let activeModal = this.modal.open(AccessCodeModal, {
            size: "sm",
            backdrop: "static",
        });
        return activeModal.result;
    }
    open(component: any, data: any) {
        let activeModal = this.modal.open(component, {
            backdrop: "static"
        });
        activeModal.componentInstance.data = data;
        return activeModal.result;
    }
    //  close() { this.activeModal.close(); }
}