import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "app/pages/login/auth.service";
import { ToastrService } from "ngx-toastr";
import { AuthMethod } from "app/pages/login/AuthMethod";
import { UserLoginInfo } from "app/app-constants";

@Component({
    selector: "pincode-authentication-modal",
    styleUrls: ["./additional-authentication.scss"],
    templateUrl: "./auth-modal.component.html",
})
export class PINCodeModal implements OnInit, OnDestroy {

    pincodeForm: FormGroup;
    authMode: AuthMethod = AuthMethod.PIN;
    loginInfo: any;
    duration: number = 60;  // in seconds
    submitted: boolean = false;

    private subscribeInterval: any;
    private clockDisplay: string;
    _focus: boolean = false;
    _isRequestOtp: boolean = false;
    refCode: string;

    constructor(
        private fb: FormBuilder,
        private activeModal: NgbActiveModal,
        private loginService: AuthService,
        private toastr: ToastrService) {
    }

    ngOnInit() {
        this.buildForm();
        if (this.authMode == AuthMethod.PIN) {
            this.clockDisplay = this.formatDisplay(this.duration);
            this.timerTick();
        }
    }
    ngOnDestroy(): void {
        if (this.subscribeInterval) {
            clearInterval(this.subscribeInterval);
        }
    }

    buildForm() {
        this.pincodeForm = this.fb.group({
            pincode: ['', [Validators.required]]
        });
    }
    get f() { return this.pincodeForm.controls; }


    onSubmit() {
        this.submitted = true;
        if (this.pincodeForm.invalid) return;
        let pinCodePayload = Object.assign({}, this.loginInfo);
        pinCodePayload.pin = this.pincodeForm.controls["pincode"].value;
        pinCodePayload.refCode = this.refCode;
        this.loginService.login(pinCodePayload).subscribe(res => {
            if (res.status) {
                this.activeModal.close(res);
            }
            else {
                this.toastr.error(res.message == undefined ? "Something went wrong!" : res.message);
                return;
            }
        });
    }

    requestOtp() {
        this.loginService.getOtp(this.loginInfo.username).subscribe(res => {
            if (res.refcode) {
                this.toastr.success("OTP is sent to your email successfully!");
                this._isRequestOtp = true;
                this.refCode = res.refcode;
                setTimeout(() => {
                    this._isRequestOtp = false;
                    this.refCode = "";
                }, 300000);
            }
            else
                this.toastr.error("Unable to create or send OTP! Please try again later");
        });
    }
    onExit() {
        this.closeModal(300);
    }
    timerTick() {
        this.subscribeInterval = setInterval(() => {
            if (this.duration > 0) {
                this.clockDisplay = this.formatDisplay(--this.duration);
            } else {
                // TIME OUT
                clearInterval(this.subscribeInterval);
                this.closeModal({ status: "timeout" });
            }

        }, 1000);
    }

    formatDisplay(value: number): string {
        let minutes: number = Math.floor(value / 60);
        let seconds: number = (value - minutes * 60);
        return `${minutes.toString()}:${seconds.toString()}`;
        //return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }

    private closeModal(result: any) {
        this.activeModal.close(result);
    }
}
