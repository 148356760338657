import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from './pages.routing';
import { NgaModule } from '../theme/nga.module';
import { AppTranslationModule } from '../app.translation.module';

import { Pages } from './pages.component';
import { ModalsModule } from 'app/shared/modals/modals.module';
import { AuthGuard } from 'app/shared/gaurds/auth.guard';
import { EnvironmentSpecificResolver } from "app/environment-specific-resolver.service";

@NgModule({
  imports: [
    CommonModule,
    AppTranslationModule,
    NgaModule,
    routing,
    ModalsModule
],
  declarations: [Pages],
  providers: [
    AuthGuard,
    EnvironmentSpecificResolver
  ]
})
export class PagesModule {
}
