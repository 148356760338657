import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateChild } from "@angular/router";
import { accessCodeKey, currentUserKey } from "app/app-constants";
import { EnvironmentSpecificService } from '../../environment-specific.service';
import { AuthService } from "app/pages/login/auth.service";
import { tap, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { UserSessionService } from "app/pages/login/user-session.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
        private userSession: UserSessionService, 
        private _envSpecificSvc: EnvironmentSpecificService
        ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | UrlTree |
        Observable<boolean | UrlTree> {

        if (this._envSpecificSvc.envSpecific.production
            || !this._envSpecificSvc.envSpecific.production
        ) {
            let isvalid: boolean = JSON.parse(localStorage.getItem(accessCodeKey));
            if (!isvalid) {
                const url = 'access-code';
                const tree = this.router.parseUrl(url);
                return tree;
            }
            else
                if (!JSON.parse(localStorage.getItem(currentUserKey))) {
                    const url = 'login';
                    const tree = this.router.parseUrl(url);
                    return tree;
                }
            //role check
            let user = this.userSession.currentUserValue;
            if (route.data.role && user.role != route.data.role) {
                const url = 'login';
                const tree = this.router.parseUrl(url);
                return tree;
            }
            //refresh
            let token = user.token;
            let refToken = user.refreshToken;
            return this.authService.refresh(token, refToken).pipe(
                map(res => {
                    if (res.status) {
                        this.userSession.saveUserInfo(res);
                        return true;
                    }
                    else {
                        const url = 'login';
                        const tree = this.router.parseUrl(url);
                        return tree;
                    }
                }));
            // return isvalid;
        }
        else
            return true;
    }
    // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    //     if (environment.production
    //     || !environment.production
    //         ) {
    //         let isvalid: boolean = JSON.parse(localStorage.getItem(accessCodeKey));
    //         if (!isvalid) {
    //             const url = 'access-code';
    //             const tree = this.router.parseUrl(url);
    //             return tree;
    //         }
    //         else
    //             if (!JSON.parse(localStorage.getItem(currentUserKey))) {
    //                 const url = 'login';
    //                 const tree = this.router.parseUrl(url);
    //                 return tree;
    //             }
    //             //refresh
    //             let token = this.authService.currentUserValue.token;
    //             let refToken = this.authService.currentUserValue.refreshToken;
    //             this.authService.refresh(token,refToken).subscribe(res=>{
    //                 console.log(res);
    //             });
    //     }
    //     return true;
    // }
}

