import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-link-modal',
  templateUrl: './payment-link-modal.html',
  styleUrls: ['./payment-link-modal.scss']
})
export class PaymentLinkModal implements OnInit {

  constructor(
    private _activeModal: NgbActiveModal,
    private toastr: ToastrService) { }
  data: string;
  ngOnInit() {
  }

  onClose(result: any) {

    this._activeModal.close(result);
  }

  onCloseClick() {

    this.onClose('close');
  }

  selectContent(event) {
    if (event.value) {
      event.select();
      document.execCommand('copy');
      this.toastr.success("Text copied to clipboard!");
    }
  }
}
