import { Directive, Input, OnInit, ElementRef, NgZone, AfterViewInit, Injector, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl, Validators } from "@angular/forms";

export interface ReCaptchaConfig {
    theme?: 'dark' | 'light';
    type?: 'audio' | 'image';
    size?: 'compact' | 'normal';
    tabindex?: number;
}

declare const grecaptcha: any;
declare global {
    interface Window {
        grecaptcha: any;
        reCaptchaLoad: () => void
    }
}
@Directive({
    selector: '[nbRecaptcha]'
})
export class RecaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor {

    private onChange: (value: string) => void;
    private onTouched: (value: string) => void;

    @Input() key: string;
    @Input() config: ReCaptchaConfig = {};
    @Input() lang: string;
    @Output() reCaptchaSucceed = new EventEmitter<string>();
    @Output() reCaptchaExpire = new EventEmitter<any>();
    private widgetId: number;
    private control: FormControl;
    constructor(
        private element: ElementRef,
        private ngZone: NgZone,
        private injector: Injector) { }
    ngOnInit(): void {
        //console.log(this.key);
        this.registerReCaptchaCallback();
        this.addScript();
    }

    ngAfterViewInit() {
        // this.injector.get(NgControl).control; 
        // this.setValidator();  
    }
    registerReCaptchaCallback() {
        window.reCaptchaLoad = () => {
            const config = {
                'sitekey': this.key,
                'callback': this.onSuccess.bind(this),
                'expired-callback': this.onExpired.bind(this)
            };
            this.widgetId = this.render(this.element.nativeElement, config);
        };
    }
    onExpired() {
        this.reCaptchaExpire.emit();
        this.ngZone.run(() => {
            this.onChange(null);
            this.onTouched(null);
        });
    }

    onSuccess(token: string) {
        //console.log(token);
        this.reCaptchaSucceed.emit(token);
        this.ngZone.run(() => {
            this.onChange(token);
            this.onTouched(token);
        });
    }
    writeValue(obj: any): void {
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
    }
    private setValidator() {
        this.control.setValidators(Validators.required);
        this.control.updateValueAndValidity();
    }
    private render(element: HTMLElement, config): number {
        return grecaptcha.render(element, config);
    }

    addScript() {
        let script = document.createElement('script');
        const lang = this.lang ? '&hl=' + this.lang : '';
        script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }
}