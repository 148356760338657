import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserProfileService } from '../user-profile.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'app/shared/modals/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password-pin',
  templateUrl: './change-password-pin.component.html',
  styleUrls: ['./change-password-pin.component.scss']
})
export class ChangePasswordPinModal implements OnInit {

  data:boolean;
  title: string;
  pwdForm: FormGroup;
  pinForm: FormGroup;
  submitted: boolean;
  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private profileService: UserProfileService) { }

  ngOnInit() {
    if(this.data){
    this.title = "Change Password";
    this.buildPwdForm();
    }
    else{
      this.title = "Change PIN Code";
      this.buildPinForm();
    }
  }
  changePassword() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.pwdForm.invalid) {
      return;
    }
    this.profileService.changePassword(this.pwdForm.getRawValue())
      .subscribe(res => {
        if (res == 200) {
          this.toastr.success("Password changed successfully!");
          this.activeModal.close();
        }
        else
          if (res == 412)
            this.toastr.error("Current password is incorrect!");
          else
            this.toastr.error("Unable to change password! An unexpected error has occurred.");
        console.log(res);
      });
  }
  changePin() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.pinForm.invalid) {
      return;
    }
    this.profileService.changePassword(this.pinForm.getRawValue())
      .subscribe(res => {
        if (res == 200) {
          this.toastr.success("PIN code changed successfully!");
          this.activeModal.close();
        }
        else
          if (res == 412)
            this.toastr.error("Incorrect password!");
          else
            this.toastr.error("Unable to change PIN code! An unexpected error has occurred.");
        console.log(res);
      });
  }
  buildPwdForm() {
    this.pwdForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
    },
      { validator: MustMatch('password', 'confirmPassword') }
    );
  }
  buildPinForm() {
    this.pinForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      pincode: ['', Validators.required],
    });
  }
  get f() { return this.pwdForm.controls; }
  get fpin() { return this.pinForm.controls; }
  closeModal() { this.activeModal.close(); }
}
// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}