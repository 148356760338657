import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UtilityService } from 'app/shared/utility.service';
import { EnvironmentSpecificService } from '../../../environment-specific.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  apiUrl: string;
  constructor(
    private http: HttpClient,
    private utility: UtilityService, 
    private _envSpecificSvc: EnvironmentSpecificService) {
    this.apiUrl = `${_envSpecificSvc.envSpecific.apiURL}/userprofile`;
  }

  getPicture(): Observable<any> {
    return this.http.get(`${this.apiUrl}/picture`).pipe(catchError(this.utility.handleError('getUserPicture')));
  }
  getProfile(): Observable<any> {
    return this.http.get(`${this.apiUrl}`).pipe(catchError(this.utility.handleError('getProfile')));
  }
  changePassword(model:any):Observable<any>{
    return this.http.put(`${this.apiUrl}/password`,model).pipe(catchError(this.utility.handleError("")));
  }
}
