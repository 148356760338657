import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-notify-modal',
  templateUrl: './payment-notify-modal.html',
  styleUrls: ['./payment-notify-modal.scss']
})
export class PaymentNotifyModal implements OnInit {

  status: string;
  data: boolean;
  message: string;
  
  constructor() { }
  
  ngOnInit() {
    this.status = this.data ? "success" : "failed";
    this.message =  this.data ? "Payment Completed":"Payment Incomplete";
  }
}
