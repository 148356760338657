import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "default-modal",
    styleUrls: ["./info-modal.component.scss"],
    templateUrl: "./info-modal.component.html"
})
export class InfoModal implements OnInit {

    header: string;
    content: string = ``;
    modelButtonCaption: string = "Save changes";
    modalCloseAction: any;

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() { }

    closeModal() {
        this.activeModal.close();

        if (this.modalCloseAction) {

            this.modalCloseAction();
        }
    }
}
