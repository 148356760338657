import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ModalService } from "../modals/modal.service";
import { UserSessionService } from "app/pages/login/user-session.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private userSession: UserSessionService,
        private modal: ModalService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body == 432)
                        this.router.navigate(['system-config']);
                }

            }), catchError(err => {
                if (err.status === 401 || err.status === 403) {
                    this.userSession.clearLoginSession();
                    this.router.navigate(['/access-code']);
                    if (err.status === 401)
                        this.modal.info("Session Expired", "Your session has expired! Please login again.");
                }
                return throwError(err);
            }));
    }
}
