import { Component, OnInit } from '@angular/core';
import { StatsService } from './stats.service';

@Component({
  selector: 'stats',
  templateUrl: './stats.html',
  styleUrls: ['./stats.scss']
})
export class StatsComponent implements OnInit {
  data: any;
  isLoaded: boolean;
  totalStorageUsage: any;

  constructor(private statsService:StatsService) { }

  ngOnInit() {
    this.loadStorageUsage();
    this.statsService.get().subscribe(res => {
      if (res) {
        this.data = res.stats;
        this.isLoaded = true;
      }
    });
  }

    private loadStorageUsage(){
      this.statsService.getStorage().subscribe(res=>{
        this.totalStorageUsage = res.storage;
      })
    }
}
