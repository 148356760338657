import { Component, Input } from '@angular/core';

import { GlobalState } from '../../../global.state';
import { AuthService } from 'app/pages/login/auth.service';
import { accessCodeKey, UserLoginInfo, currentUserKey } from 'app/app-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'ba-page-top',
  templateUrl: './baPageTop.html',
  styleUrls: ['./baPageTop.scss']
})
export class BaPageTop {

  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;
  @Input() userImage:string;
  constructor(
    private _state: GlobalState,
    private loginService: AuthService,
    private router: Router) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }
  signOut() {
    let ecc_user: UserLoginInfo = JSON.parse(localStorage.getItem(currentUserKey));
    if (ecc_user)
      this.loginService.logout(ecc_user.username).subscribe(res => {
        if (res == 200) {
          localStorage.removeItem(accessCodeKey);
          localStorage.removeItem(currentUserKey);
          this.router.navigate(['/access-code']);
        }
        
      });
  }
}
