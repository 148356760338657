import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AccessCodeService } from "app/pages/access-code/access-code.service";

@Component({
    selector: "accessCode-authentication-modal",
    templateUrl: "./accessCode-modal.html",
    styleUrls: ['./accessCode-modal.scss']
})
export class AccessCodeModal implements OnInit {
    submitted: boolean;

    codeForm: FormGroup;


    constructor(
        private fb: FormBuilder,
        private accessCodeService: AccessCodeService,
        private activeModal: NgbActiveModal,
        private toastr: ToastrService) {
    }
    ngOnInit(): void {
        this.buildForm();
    }
    buildForm() {
        this.codeForm = this.fb.group({
            accessCode: ['', [Validators.required]],
            //captcha: new FormControl()
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.codeForm.invalid) {
            this.toastr.error("Please specify access code");
            return;
        };
        this.accessCodeService.accesscodelogin(this.codeForm.value).subscribe(result => {
            if (result) {
                this.closeModal(result);
            } else {
                this.submitted = false;
                this.toastr.error("Incorrect access code! Please try again");
                return;
            }
        });
    }
    close() {
        this.closeModal(false);
    }
    private closeModal(result: any) {
        this.activeModal.close(result);
    }
}
