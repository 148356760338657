import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentSpecificService } from '../../environment-specific.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  apiUrl: string;

  constructor(
    private http: HttpClient, private _envSpecificSvc: EnvironmentSpecificService) {
    this.apiUrl = `${_envSpecificSvc.envSpecific.apiURL}/account`;
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/get/${id}`).pipe(catchError(this.handleError('getCustomer', [])));
  }

  getList(isAll: boolean): Observable<any> {
    return this.http.get(`${this.apiUrl}/${isAll}`).pipe(catchError(this.handleError('getCustomers', [])));
  }

  save(user: any): Observable<any> {
    return this.http.post(this.apiUrl, user).pipe(catchError(this.handleError('saveCustomer', [])));
  }

  reset(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${id}/reset`).pipe(catchError(this.handleError('reset', [])));
  }

  toggleStatus(id: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/togglestatus`, { data: id }).pipe(catchError(this.handleError('toggleCustomerStatus', [])))
  }
  export(ids: string[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/export`, { data: ids }, { responseType: "blob" })
      .pipe(map(res => {
        return res;
      }), catchError(this.handleError("exportAccountsData", [])));

  }
  getPaymentLink(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${id}/paymentlink`).pipe(catchError(this.handleError('getPaymentLink', [])));
  }
  notifyPaymentComplete(orderId: any, accountId: string) {
    return this.http.put(`${this.apiUrl}/${accountId}/payment-complete`, { data: orderId }).pipe(catchError(this.handleError('updatePayment', [])));
  }
  paymentCompleteOffline(invoiceId: any, accountId: string, expiryDate: any) {
    return this.http.put(`${this.apiUrl}/payment-complete-offline`, { accountId, invoiceId, expiryDate }).pipe(catchError(this.handleError('updatePayment', [])));
  }
  paymentCompleteOfflineAddon(invoiceId: any, addon: any, expiryDate: any, accountId: string) {
    return this.http.put(`${this.apiUrl}/payment-complete-offline-addon`, { addon, invoiceId, expiryDate, accountId }).pipe(catchError(this.handleError('updatePayment', [])));
  }
  notifyPaymentCompleteAddon(payload: any) {
    return this.http.put(`${this.apiUrl}/payment-complete`, payload).pipe(catchError(this.handleError('updatePayment', [])));
  }
  getPaypalEnv(): Observable<any> {
    return this.http.get(`${this.apiUrl}/paypalenv`).pipe(catchError(this.handleError('getCustomers', [])));
  }
  getAddons(acccounId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${acccounId}/addons`).pipe(catchError(this.handleError('getAddons', [])));
  }
  getAccountAddons(id: string, isAll: boolean): Observable<any> {
    return this.http.get(`${this.apiUrl}/${id}/addons/${isAll}`).pipe(catchError(this.handleError('getAddons', [])));
  }
  toggleAdoonStatus(id: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/toggleAddonstatus`, { data: id }).pipe(catchError(this.handleError('toggleCustomerStatus', [])));
  }
  activateTrialAddon(data: any, id: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/${id}/activateAddonTrial`, data).pipe(catchError(this.handleError('activateTrialAddon', [])));
  }
  sendActivationEmail(id: string) {
    return this.http.get(`${this.apiUrl}/notifyActivation/${id}`).pipe(catchError(this.handleError('getCustomer', [])));

  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
