export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'dashboard',
        authorized: 'anonymous',
        data: {
          menu: {
            title: 'Dashboard',
            icon: 'ion-android-home',
            selected: false,
            expanded: false,
            order: 0,
            helpVideoId: '2'
          }
        }
      },
      {
        path: 'users',
        role:"Admin",
        data: {
          menu: {
            title: 'Users',
            icon: 'fa fa-user',
            selected: false,
            expanded: false,
            order: 400,
            helpVideoId: '15'
          }
        },
      },
      {
        path: 'customers',
        data: {
          menu: {
            title: 'Customers',
            icon: 'fa fa-users',
            selected: false,
            expanded: false,
            order: 400,
            helpVideoId: '15'
          }
        },
      },
      {
        path: 'accounts',
        authorized: 'modules.accounts',
        data: {
          menu: {
            title: 'Accounts',
            icon: 'fa fa-usd',
            selected: false,
            expanded: false,
            order: 400,
            helpVideoId: '15'
          }
        },
      },
      {
        path: 'packages',
        authorized: 'modules.package',
        role:"Admin",
        data: {
          menu: {
            title: 'Packages',
            icon: 'fa fa-cubes',
            selected: false,
            expanded: false,
            order: 400,
            helpVideoId: '15'
          }
        },
      },
      {
        path: 'addons',
        role:"Admin",
        data: {
          menu: {
            title: 'Add-ons',
            icon: 'fa fa-puzzle-piece',
            selected: false,
            expanded: false,
            order: 400,
            helpVideoId: '15'
          }
        },
      },
      {
        path: 'payments',
        authorized: 'modules.payment',
        data: {
          menu: {
            title: 'Payments',
            icon: 'fa fa-money',
            selected: false,
            expanded: false,
            order: 400,
            helpVideoId: '15'
          }
        },
      },
      {
        path: 'settings',
        authorized: 'modules.settings',
        role:"Admin",
        data: {
          menu: {
            title: 'Settings',
            icon: 'fa fa-cog',
            selected: false,
            expanded: false,
            order: 200,
          }
        },
        children: [

          {
            path: 'database',
            authorized: 'setting.database',
            data: {
              menu: {
                title: 'Database',
                icon: 'fa fa-database',
                selected: false,
                expanded: false,
                order: 100,
                helpVideoId: '12-2'
              }
            }
          },
          {
            path: 'storage',
            authorized: 'setting.storage',
            data: {
              menu: {
                title: 'Storage',
                icon: 'fa fa-hdd-o',
                selected: false,
                expanded: false,
                order: 100,
                helpVideoId: '12-1'
              }
            }
          },
          {
            path: 'email',
            authorized: 'setting.email',
            data: {
              menu: {
                title: 'Email',
                icon: 'fa fa-at',
                selected: false,
                expanded: false,
                order: 100,
                helpVideoId: '12-5'
              }
            }
          },
          {
            path: 'api-config',
            authorized: 'WolfcomAdmin',
            data: {
              menu: {
                title: 'Api',
                icon: 'fa fa-sm fa-connectdevelop fa-icon-custom',
                selected: false,
                expanded: false,
                order: 100
              }
            }
          },
          {
            path: 'access-control',
            authorized: 'module.access',
            data: {
              menu: {
                title: 'Access Control',
                icon: 'fa fa-wrench',
                selected: false,
                expanded: false,
                order: 100
              }
            }
          },
        ]
      },
      {
        path: 'security',
        authorized: 'modules.security',
        role:"Admin",
        data: {
          menu: {
            title: 'Security',
            icon: 'fa fa-lock',
            selected: false,
            expanded: false,
            order: 200,
          }
        },
        children: [
          {
            path: 'sessions',
            data: {
              menu: {
                title: 'Sessions',
                icon: 'fa fa-id-badge',
                selected: false,
                expanded: false,
                order: 100,
                helpVideoId: '13-3'
              }
            }
          },
          {
            path: 'activity-logs',
            authorized: 'security.view_activity_logs',
            data: {
              menu: {
                title: 'Activity Logs',
                icon: 'ion-ios-glasses',
                selected: false,
                expanded: false,
                order: 100,
                helpVideoId: '13-1'
              }
            }
          }
        ]
      },
      {
        path: 'analytics',
        data: {
          menu: {
            title: 'Analytics',
            icon: 'ion-pie-graph',
            selected: false,
            expanded: false,
            order: 500,
            helpVideoId: '14'
          }
        },
      }
    ]
  }
];
